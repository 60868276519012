import type { OrderlineCoverage } from '../../api-models'
import { createHttpEndpoint } from '../../utils'

import type { OrderlineDetails } from './types/orderlineDetails'

export type getOrderlineDetailsResponse = OrderlineDetails
export type getOrderlineDetailsParams = {
  orderlineId: number
}

/**
 * Retrieve data for an orderline
 *
 * @see {@link  https://devportal.backmarket.io/catalog/default/api/after-sale-customer-orders-api/definition#tag/orderline/operation/get-orderline}
 */
export const getOrderlineDetails =
  createHttpEndpoint<getOrderlineDetailsResponse>({
    method: 'GET',
    operationId: 'get-orderline',
    path: '/bm/orders/v1/orderline/:orderlineId',
  })

/**
 * Retrieve cancellation reason available for an orderline
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-customer-orders-api/definition#tag/orderline/operation/get-orderline-cancellation-reasons}
 */
export const getOrderlineCancellationReasons = createHttpEndpoint({
  method: 'GET',
  operationId: 'get-orderline-cancellation-reasons',
  path: '/bm/orders/v1/orderline/:orderlineId/cancel',
})

/**
 * Request a cancellation for this orderline
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-customer-orders-api/definition#tag/orderline/operation/post-orderline-cancellation-request}
 */
export const postOrderlineCancellationRequest = createHttpEndpoint({
  method: 'POST',
  operationId: 'post-orderline-cancellation-request',
  path: '/bm/orders/v1/orderline/:orderlineId/cancel',
})

/**
 * Orderline Timeline
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/my-orders-api/definition#tag/timeline/operation/orderline_timeline}

 */
export const getOrderlineTimeline = createHttpEndpoint({
  method: 'GET',
  operationId: 'get-orderline-timeline',
  path: '/bm/orderlines/:orderlineId/timeline',
})

/**
 * Retrieve warranties available for an orderline
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/orders-mobile-api/definition#tag/orderline/operation/get-orderline-coverage}
 */
export const getOrderlineWarranties = createHttpEndpoint<OrderlineCoverage>({
  method: 'GET',
  operationId: 'getOrderlineWarranties',
  path: '/bm/orders/v1/orderline/:orderlineId/coverage',
})
