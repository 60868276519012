export default {
  confirm: {
    id: 'dashboard_cancel_insurances_confirm_label',
    defaultMessage: 'Confirm my cancellation',
  },
  refund: {
    id: 'dashboard_cancel_insurances_refund_mention',
    defaultMessage: "You'll be refunded of {amount, html}.",
  },
  heading1: {
    id: 'dashboard_cancel_insurances_route_title',
    defaultMessage: 'Cancel my insurance',
  },
  heading2: {
    id: 'dashboard_cancel_insurances_subtitle',
    defaultMessage: "Life without risks isn't life",
  },
  error: {
    id: 'dashboard_cancel_insurances_error',
    defaultMessage: 'An error occured',
  },
  tryAgain: {
    id: 'dashboard_cancel_insurances_try_again',
    defaultMessage: 'Try again',
  },
}
